import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { countries, phoneMaxLength } from '../../../utils/commonVariables';
import Check from '../../../assets/images/profile/edit/check.svg';
import { getTwoDigits } from '../../../utils/helper';
import { otpVerify, getOTP } from '../../../actions/profile/phone';
import VerifiedGreen from "../../../assets/images/profile/edit/verfied-green.svg";
import ErrorRed from "../../../assets/images/profile/edit/error-red.svg";
const texts = {
  title: 'Personal',
  otp: 'OTP',
},
  errors = {
    invalidPhone: 'Please enter valid phone number',
    invalidDate: 'Please enter valid date',
    otpIncorrect: 'Incorrect OTP',
  };

function PhoneNumber(props) {
  const [countryCode, setCountryCode] = useState(props.user.user.profile.country_code || '+91');
  const [phone, setPhone] = useState(props.user.user.profile.phone || '');
  const [isWhatsAppOpted, setIsWhatsAppOpted] = useState(true);
  const [verifiedPhone, setVerifiedPhone] = useState(props.user.user.profile.verified_phone || 0);
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtp] = useState('');
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [apiErrorText, setApiErrorText] = useState(false);
  const [otpMin, setOtpMin] = useState(0);
  const [otpSeconds, setOtpSeconds] = useState(59);
  const [canResendOtp, setCanResendOtp] = useState(false);
  const { token, otpVerify, getOTP, addToFilledItems, removeFromFilledItems, isVerification, useFor = "" } =
    props;
  let timerRef = useRef(null);

  // will check if the current number and original number are same
  // if they are verified phone will be of original phone
  const checkForSameNumber = () => {
    const originalPhone = props.user.user.profile.phone || '',
      originalCode = props.user.user.profile.country_code || '';
    if (
      `${originalCode}${originalPhone}` === `${countryCode}${phone}` &&
      props.user.user.profile.verified_phone
    ) {
      setVerifiedPhone(props.user.user.profile.verified_phone);
      if (isVerification) {
        addToFilledItems('verified_phone', true);
      } else {
        addToFilledItems(`phone`, {
          phone: phone,
          country_code: countryCode,
        });
      }
    } else {
      setVerifiedPhone(0);
      setCanResendOtp(false);
      setIsVerifying(false);
      if (isVerification) {
        removeFromFilledItems('verified_phone');
      } else {
        if (!countryCode || !phone) {
          removeFromFilledItems('phone');
        } else {
          addToFilledItems('phone', {
            phone,
            country_code: countryCode,
          });
        }
      }
    }
  };

  // checking for same number whenever country code and phone changes
  useEffect(() => {
    checkForSameNumber();
    setApiErrorText('');
  }, [countryCode, phone]);

  // adding + to value of the country code
  const handleCallingCodeChange = (e) => {
    let value = e.target.value;
    setCountryCode(`+${value}`);
  };

  //verify otp
  const verifyOtp = (e) => {
    e.preventDefault();
    if (!otp) return;
    const data = {
      otp,
      phone,
      whatsapp_opt_in: isWhatsAppOpted ? 1 : 0
    };
    otpVerify(data, token).then((_) => {
      if (!_.data) {
        setOtpInvalid(true);
        removeFromFilledItems('verified_phone');
        const errorMsg = get(_, "errors.display_message", "");
        if (errorMsg) {
          setApiErrorText(errorMsg)
        } else {
          setApiErrorText(errors.otpIncorrect)
        }
      } else {
        setOtpInvalid(false);
        setVerifiedPhone(1);
        setOtp('');
        addToFilledItems('verified_phone', `${countryCode}${phone}`);
        setApiErrorText("")
      }
    });
  };

  useEffect(() => {
    if (isVerification) {
      if (props.user.user.profile.verified_phone) {
        addToFilledItems('verified_phone', `${countryCode}${phone}`);
      }
    } else {
      if (props.user.user.profile.phone && props.user.user.profile.country_code) {
        addToFilledItems('phone', {
          country_code: props.user.user.profile.country_code,
          phone: props.user.user.profile.phone,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isVerifying) {
      timerRef.current = setInterval(() => {
        setOtpSeconds(
          otpSeconds === 0 && otpMin === 1
            ? 59
            : otpSeconds === 0 && otpMin === 0
              ? 0
              : otpSeconds - 1,
        );
        setOtpMin(otpSeconds === 0 && otpMin === 1 ? otpMin - 1 : otpMin);
        if (otpMin === 0 && otpSeconds === 0) {
          clearInterval(timerRef.current);
          setCanResendOtp(true);
        }
      }, 1000);
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [isVerifying, otpSeconds, otpMin]);

  // get otp
  const getOtp = (e) => {
    if (!countryCode || !phone) return;
    let dataToSend = {
      profile: {
        country_code: countryCode,
        phone,
      },
    };
    setOtpInvalid(false);
    setOtp("");
    setApiErrorText("")
    getOTP(dataToSend, token).then((res) => {
      if (!!res.data) {
        setIsVerifying(true);
        setCanResendOtp(false);
        setOtpMin(0);
        setOtpSeconds(59);
        // startTimer();
      } else {
        const errorMsg = get(res, 'errors.display_message');
        if (errorMsg) {
          setApiErrorText(errorMsg);
        } else {
          setApiErrorText('');
        }
      }
    });
  };

  const updatePhoneNumber = () => {
    removeFromFilledItems('verified_phone');
    setOtp("")
    setApiErrorText("")
    setOtpSeconds(59)
    setOtpMin(0)
    setCanResendOtp(false)
    setVerifiedPhone(0)
    setPhone("")
    setIsVerifying(false)
  }

  const handleOtpChange = (e) => {
    setOtp(e.target.value.slice(0, 6));
    setApiErrorText("");
    setOtpInvalid(false)
  }
  return (
    <div className={`profile-edit-modal__form__group profile-edit-modal__form__phone__container--without-position ${apiErrorText || (phone && phone.length >= 1 && !verifiedPhone) ? "profile-edit-modal__form__phone__container--without-position--margin" : ""}`}>
      <label className="form-label">Phone Number</label>
      <div className="profile-edit-modal__form__group--multi">
        <div className="profile-edit-modal__form__phone__container" style={{
          marginRight: "14px",
          width: "48%"
        }}>
          <select
            className={`profile-edit-modal__form__phone__codes ${!!verifiedPhone ? 'disabled' : ''}`}
            onChange={handleCallingCodeChange}
            value={countryCode}
          >
            <option value={countryCode}>{countryCode}</option>
            {countries.map((_, index) => {
              return (
                <option key={`calling_code-${index}`} value={_.calling_code}>
                  {_.country} {_.emoji} (+{_.calling_code})
                </option>
              );
            })}
          </select>
          <input
            type="number"
            value={phone || ''}
            name="phone"
            maxLength={phoneMaxLength}
            className={`profile-edit-modal__form__phone__input form-input form-input--secondary  ${!!verifiedPhone ? 'form-input--disabled' : ''}`}
            disabled={!!verifiedPhone}
            onChange={(e) => {
              setPhone(e.target.value.slice(0, 15));
              setApiErrorText("");
              setOtpInvalid(false)
              if (isNaN(e.target.value) || !e.target.value) {
                setInvalidPhone(true);
                // removing phone from filled items if value is invalud
                if (!isVerification) {
                  removeFromFilledItems('phone');
                } else {
                  removeFromFilledItems('verified_phone');
                }
              } else {
                setInvalidPhone(false);
                // adding phone to filled items if value is valid
                if (!isVerification) {
                  addToFilledItems('phone', {
                    country_code: countryCode,
                    phone: e.target.value,
                  });
                }
              }
            }}
          />
          {
            !!verifiedPhone ?
              <img src={VerifiedGreen} alt="phone verified" style={{
                position: "absolute",
                left: "42%"
              }} />
              : null
          }
        </div>
        <div className="profile-edit-modal__form__phone">
          <div className="profile-edit-modal__form__phone__container" style={{
            gap: !!verifiedPhone ? null : "10px"
          }}>
            {!!verifiedPhone ? (
              <div style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between"
              }}>
                <p className="profile-edit-modal__form__phone__verified">
                  Your phone is Verified
                </p>
                {
                  useFor !== "creation" && <button type="button"
                    className="profile-edit-modal__btn profile-edit-modal__btn--secondary"
                    style={{ padding: "0px 4px" }}
                    onClick={updatePhoneNumber}
                  >
                    Update Number
                  </button>
                }
              </div>
            ) : isVerifying ? (
              <>
                <div className="profile-edit-modal__form__phone__container">
                  <input
                    className="profile-edit-modal__form__phone__otp form-input form-input--secondary"
                    onChange={handleOtpChange}
                    value={otp}
                    placeholder={texts.otp}
                    maxLength="6"
                    name="otp"
                    type="number"
                    style={{
                      border: otpInvalid ? "1px solid red" : null,
                      textAlign: "center"
                    }}
                  />

                </div>
                {canResendOtp ? (
                  <button
                    type="button"
                    onClick={getOtp}
                    className="profile-edit-modal__form__phone__btn"
                  >
                    Resend
                  </button>
                ) : <span
                  className="profile-edit-modal__form__phone__btn--timer
                              profile-edit-modal__form__phone__btn
                              profile-edit-modal__form__phone__btn--disabled"
                  style={{
                    textDecoration: "none",
                    color: "#171717"
                  }}
                >
                  0{otpMin}:{getTwoDigits(otpSeconds)}
                </span>}

                <button
                  type="button"
                  onClick={verifyOtp}
                  className="profile-edit-modal__btn profile-edit-modal__btn--secondary"
                  style={{
                    opacity: otp.length < 6 ? "0.5" : null,
                    padding: "0px",
                    marginLeft: "16px",
                  }}
                >
                  Verify OTP
                </button>

              </>
            ) : phone &&
              countryCode &&
              ((phone.length === 10 && countryCode === '+91') ||
                (phone.length >= 7 && countryCode !== '+91' && phone.length <= 15)) &&
              !invalidPhone ? (
              <button
                type="button"
                className="profile-edit-modal__btn profile-edit-modal__btn--secondary"
                onClick={() => {
                  if (invalidPhone) return;
                  getOtp();
                }}
                style={{
                  padding: "0px"
                }}
              >
                Verify Phone
              </button>
            ) : <button
              type="button"
              className="profile-edit-modal__btn profile-edit-modal__btn--secondary"
              style={{ opacity: "0.5", padding: "0px" }}
            >
              Verify Phone
            </button>}
          </div>

        </div>
      </div>
      {/* {otpInvalid && <p className="error-text">{errors.otpIncorrect}</p>} */}
      {!verifiedPhone ? <div className="profile-form-modals__input__checkbox--container">
        <input
          name="whatsapp_opt_in"
          id="whatsapp_opt_in"
          onChange={() => setIsWhatsAppOpted(prev => !prev)}
          type="Checkbox"
          className="profile-form-modals__input__checkbox"
          checked={isWhatsAppOpted} />
        <label htmlFor="whatsapp_opt_in">
          Receive updates via SMS and WhatsApp
        </label>
      </div> : null}
      {invalidPhone && <p className="error-text profile-edit-modal__position-with-margin">{errors.invalidPhone}</p>}
      {apiErrorText && (
        <p className="error-text profile-edit-modal__position-with-margin">
          {apiErrorText}
        </p>
      )}
      {(phone && phone.length >= 1 && !verifiedPhone && !otpInvalid && !apiErrorText) && (
        <p className="profile-edit-modal__form__phone__confirm-text">
          Please confirm if this phone number belongs to you by entering the OTP sent on your phone.
        </p>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

const mapDispatchToProps = {
  otpVerify,
  getOTP,
};

PhoneNumber.propTypes = {
  addToFilledItems: PropTypes.func,
  removeFromFilledItems: PropTypes.func,
  isVerification: PropTypes.bool,
  userFor: PropTypes.string
};
export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
