import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { formatDate, decodeString } from "../../../../utils/helper";
import SharedIcon from "../../../../assets/images/feed/reply.svg";
import TrashIcon from "../../../../assets/images/feed/options/trash.svg";
import DownVoteIcon from "../../../../assets/images/feed/options/thumb-down.svg";
import BlockProfileIcon from "../../../../assets/images/feed/options/block-user.svg";
import EditIcon from "../../../../assets/images/feed/options/edit.svg";
import {
  deleteAPost,
  showEditAPostModal,
  blockProfile,
} from "../../../../actions/feed/common";
import Avatar from "../../../../components/user/Avatar";
import { toggleShareAPostModal } from "../../../../actions/common/modals";
import {
  getInternalLink,
  homeLink,
  quizLinks,
  surveyLinks,
} from "../../../../utils/internalLinks";
import { ProfileUrls } from "../../../../utils/links";
import { collaborationInternalPath } from "../../../../utils/collaborationHelper";
import LoggedoutUserBar from "../../../../components/bars/LoggedoutUserBar";
import ReportContentModal from "../../../../components/modals/flag/ReportContentModal";
import UserName from "../../../../components/profile/UserName";
import { availableFeedCardPostTypes, genericErrorText } from "../../../../utils/commonVariables";
import _ from "lodash";
import DiscardPostDraft from "../../../../components/modals/common/DiscardPost";
import { showToast } from "../../../../components/create/utils";
import ReportFlag from "../../../../assets/images/feed/options/report-flag.svg";
import { getFeed } from "../../../../actions/feed/feed";
import { toast, ToastContainer } from "react-toastify";
import get from "lodash/get";
import showToaster, { TOAST_MESSAGE } from "../../../../components/misc/Toaster";
class UserBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      showReportModal: false,
      showBlockProfileModal: false,
      isRemovePost: false,
      postText: "",
    };
    this.toggleFeedCardOptions = this.toggleFeedCardOptions.bind(this);
    //report
    this.toggleShowReportModal = this.toggleShowReportModal.bind(this);

    this.toggleShowBlockProfileModal =
      this.toggleShowBlockProfileModal.bind(this);
    // Creating ref for options pupup
    this.optionsRef = React.createRef();
    // deleting a post
    this._handleRemovePost = this._handleRemovePost.bind(this);
    this._handlePostRemove = this._handlePostRemove.bind(this);
    // editing a post functionality
    // this._handleBlockProfile = this._handleBlockProfile.bind(this);
    this._handleEditAPost = this._handleEditAPost.bind(this);
  }

  _handleBlockProfile = (event) => {
    const { token, profile, getFeed, blockProfile } = this.props;
    blockProfile(token, profile.id, "profile", true).then((res) => {
      const isError = get(res, "errors.status", false)
      if (!!res && !isError) {
        showToaster({
          text: TOAST_MESSAGE.BlockedProfile, id: profile.id,
          handleAction: this._handleUnblockProfile, actionText: "Unblock"
        });
        this.setState({ showBlockProfileModal: false });
      } else {
        const errorText = get(res, "errors.display_message", genericErrorText.general);
        this.setState({ showBlockProfileModal: false });
        alert(errorText);
      }
    });
  };
  _handleUnblockProfile = () => {
    const { token, profile, blockProfile } = this.props;
    blockProfile(token, profile.id, "profile", false).then((res) => {
      const isError = get(res, "errors.status", false);
      if (!!res && !isError) {
        showToaster({ text: TOAST_MESSAGE.UnblockProfile });
      } else {
        const errorText = get(res, "errors.display_message", genericErrorText.general);
        alert(errorText);
      }
    });
  };

  async _handleRemovePost(event) {
    // event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    const {
      postId,
      postType,
      isShared,
      sharedId,
      deleteAPost,
      token,
      isDetailsPage,
      history,
      profile,
      cardData,
    } = this.props;
    const { collaborate, shared } = cardData;
    if (!postId || !postType) {
      return;
    }

    await deleteAPost(
      postType,
      postId,
      token,
      isShared,
      sharedId,
      profile.id
    ).then((val) => {
      if (val && isDetailsPage) {
        history.replace(homeLink);
      }
    });
    this.setState({ isRemovePost: false });
    if (shared) {
      showToaster({ text: TOAST_MESSAGE.SharedDelete });
      return;
    } else if (
      collaborate &&
      collaborate.collaborate_type === "product-review"
    ) {
      showToaster({ text: TOAST_MESSAGE.delete("tasting") });
      return;
    }
    showToaster({ text: TOAST_MESSAGE.delete(postType) });
  }

  _handlePostRemove() {
    this.setState({ isRemovePost: true });
    const { postType, cardData } = this.props;
    const { collaborate, shared } = cardData;
    if (postType === "quiz" && !shared) {
      this.setState({
        postText: "Quiz",
      });
    } else if (postType === "polling" && !shared) {
      this.setState({
        postText: "Poll",
      });
    } else if (postType === "surveys" && !shared) {
      this.setState({
        postText: "Survey",
      });
    } else if (postType === "photo" && !shared) {
      this.setState({
        postText: "Photo",
      });
    } else if (
      collaborate &&
      collaborate.collaborate_type === "product-review" &&
      !shared
    ) {
      this.setState({
        postText: "Tasting",
      });
    } else if (
      ((collaborate && collaborate.collaborate_type === "collaborate") ||
        postType === "collaborate") &&
      !shared
    ) {
      this.setState({
        postText: "Collaboration",
      });
    } else if (shared) {
      this.setState({ postText: "Shared Post" });
    } else {
      this.setState({ postText: "Post" });
    }
  }

  _handleEditAPost() {
    const {
      postType,
      cardData,
      showEditAPostModal,
      toggleShareAPostModal,
      history,
      postId,
      isShared,
    } = this.props;
    if (isShared) {
      toggleShareAPostModal(cardData);
      return;
    }
    if (postType === "collaborate") {
      history.push(
        collaborationInternalPath.edit(
          cardData[postType].collaborate_type,
          postId
        )
      );
      return;
    }
    if (postType === availableFeedCardPostTypes.survey) {
      history.push(surveyLinks.edit(postId));
      return;
    }
    if (postType === availableFeedCardPostTypes.quiz) {
      history.push(quizLinks.edit(postId));
      return;
    }
    showEditAPostModal(cardData, postType);
  }

  componentWillUnmount() {
    if (this.optionsRef.current) {
      this.optionsRef.current.removeEventListener("click", () => { });
    }
  }

  toggleShowReportModal() {
    this.setState({
      showReportModal: !this.state.showReportModal,
    });
  }

  toggleShowBlockProfileModal() {
    this.setState({
      showBlockProfileModal: !this.state.showBlockProfileModal,
    });
  }
  toggleFeedCardOptions() {
    if (this.state.showOptions) {
      document.removeEventListener("click", this.toggleFeedCardOptions);
    } else {
      document.addEventListener("click", this.toggleFeedCardOptions);
    }
    this.setState({
      showOptions: !this.state.showOptions,
    });
  }

  render() {
    const {
      profile,
      createdAt,
      isShared,
      postType,
      postId,
      sharedId,
      isPublic,
      publicClickAction,
      isAd,
      cardData,
      showFlagOptions,
      showOptionsIcon,
      hideMetaBar,
      isOwner,
    } = this.props,
      profileImage = profile.image_meta
        ? profile.image_meta.original_photo ||
        JSON.parse(profile.image_meta).original_photo
        : profile.imageUrl,
      currentPostIdToCheck = isShared
        ? `${postType}-${postId}-share-${sharedId}`
        : !isAd
          ? `${postType}-${postId}`
          : "",
      linkToPost = getInternalLink(
        postType,
        postId,
        sharedId,
        false,
        cardData && cardData && cardData.collaborate_type
          ? cardData.collaborate_type
          : null
      ),
      directLinkToPost = isShared
        ? postType === "collaborate"
          ? collaborationInternalPath.view(
            cardData[postType].collaborate_type,
            cardData[postType].id
          )
          : linkToPost
        : postType === "collaborate"
          ? collaborationInternalPath.view(cardData.collaborate_type, postId)
          : linkToPost,
      linkToProfile = ProfileUrls.userProfileLink(profile.handle, profile.id),
      isEdited =
        cardData &&
          cardData[postType] &&
          cardData[postType].created_at &&
          cardData[postType].updated_at &&
          !isShared
          ? cardData[postType].created_at !== cardData[postType].updated_at
          : false;
    const isEditedDetails =
      cardData && cardData.created_at && cardData.updated_at && !isShared
        ? cardData.created_at !== cardData.updated_at
        : false;
    if (isPublic)
      return (
        <LoggedoutUserBar
          name={profile.name}
          createdAt={formatDate.post(createdAt)}
          isEdited={isEdited}
          isVerified={!!profile.verified}
          publicClickAction={publicClickAction}
          isShared={isShared}
          image={profileImage}
        />
      );
    return (
      <>
        <div className="user-block">
          <div className="user-block__details">
            <Link to={linkToProfile}>
              <Avatar
                className="user-block__image"
                alt={decodeString(profile.name)}
                src={profileImage}
                profile={profile}
              />
            </Link>
            <div className="user-block__info">
              <Link
                to={linkToProfile}
                className={`user-block__info__name ${!!profile.verified && "user-block__info__name--verified"
                  }`}
              >
                <UserName
                  isVerified={!!profile.verified || false}
                  name={profile.name}
                />
              </Link>
              {(createdAt || isShared || isEdited) && (
                <p className="user-block__info__secondary">
                  {(isEdited || isEditedDetails) && !isShared && !isAd && (
                    <>
                      <span>Edited</span>
                      <span className="user-block__info__secondary--divider">
                        &bull;
                      </span>
                    </>
                  )}
                  {isShared && !isAd && (
                    <Link
                      to={{ pathname: linkToPost, state: { cardData } }}
                      className="user-block__info__secondary user-block__info__secondary--link"
                    >
                      <img
                        className="user-block__info__secondary--shared-icon"
                        src={SharedIcon}
                        alt="shared post"
                      />
                      Shared
                    </Link>
                  )}
                  {isAd && <span>Promoted</span>}
                  {(isShared || isAd) && createdAt && (
                    <span className="user-block__info__secondary--divider">
                      &bull;
                    </span>
                  )}
                  {createdAt && (
                    <Link
                      to={{ pathname: directLinkToPost, state: { cardData } }}
                      className="user-block__info__secondary user-block__info__secondary--link"
                    >

                      {isShared ?
                        formatDate.post(
                          get(cardData, "shared.updated_at", createdAt)
                        )
                        :
                        formatDate.post(
                          get(cardData, `${postType}.updated_at`, createdAt)
                        )}
                    </Link>
                  )}
                </p>
              )}
            </div>
          </div>
          {showOptionsIcon ? (
            <div className="user-block__action">
              <button
                onClick={this.toggleFeedCardOptions}
                className={`user-block__action__btn
                            ${this.state.showOptions
                    ? "user-block__action__btn--active"
                    : ""
                  }`}
              >
                <span className="user-block__action__btn__text">•••</span>
              </button>
              {this.state.showOptions && (
                <div className="user-block__action__container">
                  <ul
                    ref={this.optionsRef}
                    className="user-block__action__container__list"
                  >
                    {this.props.children}
                    {postType !== "polling" ||
                      (postType === "polling" && (isOwner || isShared)) ? (
                      <li className="user-block__action__container__list__item">
                        <button
                          onClick={this._handleEditAPost}
                          tabIndex="0"
                          className="user-block__action__container__list__item__btn"
                        >
                          <img
                            className="user-block__action__container__list__item__btn__icon"
                            src={EditIcon}
                            alt="edit post"
                          />
                          <span className="user-block__action__container__list__item__btn__text">
                            Edit Post
                          </span>
                        </button>
                      </li>
                    ) : null}
                    <li className="user-block__action__container__list__item">
                      <button
                        onClick={this._handlePostRemove}
                        tabIndex="0"
                        className="user-block__action__container__list__item__btn"
                      >
                        <img
                          className="user-block__action__container__list__item__btn__icon"
                          src={TrashIcon}
                          alt="remove post"
                        />
                        <span className="user-block__action__container__list__item__btn__text user-block__action__container__list__item__btn__text--red">
                          Remove
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : hideMetaBar || isAd || !showFlagOptions ? null : (
            <div className="user-block__action">
              <button
                onClick={this.toggleFeedCardOptions}
                className={`user-block__action__btn
                            ${this.state.showOptions
                    ? "user-block__action__btn--active"
                    : ""
                  }`}
              >
                <span className="user-block__action__btn__text">•••</span>
              </button>
              {this.state.showOptions && (
                <div className="user-block__action__container">
                  <ul
                    ref={this.optionsRef}
                    className="user-block__action__container__list"
                  >
                    <li className="user-block__action__container__list__item">
                      <button
                        onClick={this.toggleShowReportModal}
                        tabIndex="0"
                        className="user-block__action__container__list__item__btn"
                      >
                        <img
                          className="user-block__action__container__list__item__btn__icon"
                          src={ReportFlag}
                          alt="report post"
                        />
                        <span className="user-block__action__container__list__item__btn__text">
                          Report Content
                        </span>
                      </button>
                    </li>
                    <li className="user-block__action__container__list__item">
                      <button
                        onClick={this.toggleShowBlockProfileModal}
                        tabIndex="0"
                        className="user-block__action__container__list__item__btn"
                      >
                        <img
                          className="user-block__action__container__list__item__btn__icon"
                          src={BlockProfileIcon}
                          alt="report post"
                        />
                        <span className="user-block__action__container__list__item__btn__text">
                          Block Profile
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.showReportModal && (
          <ReportContentModal
            handleClose={this.toggleShowReportModal}
            isOpen={this.state.showReportsModal}
            type="content"
            {...this.props}
          />
        )}
        {this.state.showBlockProfileModal && (
          <DiscardPostDraft
            modalTitle={`Block ${profile.name}?`}
            text={`${profile.name} will no longer be able to follow or message you, and you will not see
          notifications from ${profile.name}`}
            handleClose={() => this.setState({ showBlockProfileModal: false })}
            handleAction={this._handleBlockProfile}
            actionText="Block"
          />
        )}
        {this.state.isRemovePost && (
          <DiscardPostDraft
            modalTitle={`Delete ${this.state.postText}?`}
            text={`This action cannot be undone. Are you sure you want to delete this ${this.state.postText.toLowerCase()}?`}
            handleClose={() => this.setState({ isRemovePost: false })}
            handleAction={this._handleRemovePost}
            actionText="Delete"
          />
        )}
      </>
    );
  }
}

UserBar.propTypes = {
  profile: PropTypes.object.isRequired,
  isShared: PropTypes.bool,
  showOptionsIcon: PropTypes.bool,
  postId: PropTypes.any,
  postType: PropTypes.string,
  sharedId: PropTypes.number,
  cardData: PropTypes.object,
  createdAt: PropTypes.string,
  isOwner: PropTypes.bool,
  isAd: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  isPublic: PropTypes.bool,
  publicClickAction: PropTypes.func,
  hideMetaBar: PropTypes.bool,
  showFlagOptions: PropTypes.bool,
  handleDelete: PropTypes.func,
  getFeed: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

const mapDispatchToProps = {
  deleteAPost,
  showEditAPostModal,
  toggleShareAPostModal,
  getFeed,
  blockProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserBar)
);
