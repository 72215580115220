import React, { useState } from "react";
import ProfilePhoto from "./ProfilePhoto";
import DateOfBirth from "./DateOfBirth";
import PhoneNumber from "./PhoneNumber";
import Gender from "./Gender";
import City from "./City";
import About from "./About";
import { genericErrorText, maxFileSize } from "../../../utils/commonVariables";
import { connect } from "react-redux";
import { updateUser } from "../../../actions/profile/update";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ProfileUrls } from "../../../utils/links";
import { ToastContainer } from "react-toastify";
import CommonPortal from "../common/CommonPortal";
import { getOTP, otpVerify } from "../../../actions/profile/phone";
import { PROFILE_COMPLETION_GA, PROFILE_KEYS, PROMPT_UPDATE_FAILURE_TEXT, PROMPT_UPDATE_SUCCESS_TEXT, promptTexts } from "./constants";
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import showToaster from "../../misc/Toaster";


const availablePromptTypes = {
    [PROFILE_KEYS.PROFILE_PHOTO]: ProfilePhoto,
    [PROFILE_KEYS.PHONE]: PhoneNumber,
    [PROFILE_KEYS.DATE_OF_BIRTH]: DateOfBirth,
    [PROFILE_KEYS.GENDER]: Gender,
    [PROFILE_KEYS.CITY]: City,
    [PROFILE_KEYS.HOMETOWN]: City,
    [PROFILE_KEYS.ABOUT]: About
}

const ProfilePrompt = (props) => {
    const { updateUser, token, user: { profile }, otpVerify, handleGAEvents, onClose, continueButtonAction, profileItemsToBeFilled, promptToShowIndex, onUpdateSuccess, getOTP } = props;
    const [modalTitle, setModalTitle] = useState("");
    const [form, setForm] = useState({
        showForm: false,
        error: null,
        selectedMedia: null,
        isUpdating: false,
        value: "",
    });
    const { isUpdating, showForm, isInteractedWithForm } = form;

    const toggleForm = () => {
        if (!isInteractedWithForm) {
            handleGAEvents(PROFILE_COMPLETION_GA.INTERACT)
        }
        setModalTitle(formModalTitle);
        setForm((prevForm) => {
            return { ...prevForm, value: "", showForm: !prevForm.showForm, isInteractedWithForm: true }
        });
    };

    const handleMediaChange = (e) => {
        if (isUpdating) return;
        if (!e) return;
        let files = e.target.files;
        if (files.length) {
            let currentFile = files[0];
            if (
                currentFile.size > maxFileSize ||
                currentFile.type.indexOf("image") === -1
            ) {
                const errorText = currentFile.size > maxFileSize ? "Maximum file size should not exceed 10 MB" : genericErrorText.general;
                showToaster({ text: errorText });
                return false;
            } else {
                setForm((preForm) => {
                    return { ...preForm, showForm: !preForm.showForm, error: null, selectedMedia: currentFile }
                });
            }
        }
    }

    const handleClose = () => {
        if (showForm) {
            setModalTitle("");
            setForm((preForm) => {
                return { ...preForm, showForm: false, error: null, selectedMedia: null, }
            });
            return;
        };
        if (!isInteractedWithForm) {
            handleGAEvents(PROFILE_COMPLETION_GA.SKIP);
        }
        onClose("PROMPT_DISCARD");
        if (continueButtonAction && typeof continueButtonAction === "function") {
            continueButtonAction();
        }
    }

    const handleUpdate = (data, isMultiPart = false) => {
        setForm((prevForm) => ({
            ...prevForm,
            isUpdating: true,
            selectedMedia: null,
        }));

        const handleError = (error) => {
            const errorMessage = (error && error.display_message) || error || "Ooops, something went wrong.";
            setForm((prevForm) => ({
                ...prevForm,
                isUpdating: false,
                value: "",
                error: errorMessage,
            }));
            showToaster({ text: errorMessage, id: "prompt-toast" });
        };

        const handleSuccess = (successMessage) => {
            setForm((prevForm) => ({
                ...prevForm,
                isUpdating: false,
                error: null,
                value: "",
            }));
            showToaster({ text: successMessage, id: "prompt-toast" });
            onClose();
            if (continueButtonAction && typeof continueButtonAction === "function") {
                continueButtonAction();
            }
        };

        const processOtpVerify = () => {
            otpVerify(data, token)
                .then((val) => {
                    if (val.errors && val.errors.status) {
                        handleError(val.errors.display_message || PROMPT_UPDATE_FAILURE_TEXT);
                    } else {
                        handleSuccess(PROMPT_UPDATE_SUCCESS_TEXT);
                    }
                })
                .catch((error) => {
                    handleError(PROMPT_UPDATE_FAILURE_TEXT);
                });
        };

        const processUpdateUser = () => {
            updateUser(profile.id, data, token, isMultiPart)
                .then((val) => {
                    if (val.errors && val.errors.length) {
                        handleError(PROMPT_UPDATE_FAILURE_TEXT);
                    } else {
                        handleSuccess(PROMPT_UPDATE_SUCCESS_TEXT);
                        onUpdateSuccess("PROMPT_SUCCESS");
                    }
                })
                .catch((error) => {
                    handleError(PROMPT_UPDATE_FAILURE_TEXT);
                });
        };

        if (ComponentToShowType === "phone") {
            processOtpVerify();
        } else {
            processUpdateUser();
        }
    };

    const getOTPWithDispatch = (data, token) => {
        return getOTP(data, token);
    };


    const ComponentToShowType = (profileItemsToBeFilled && profileItemsToBeFilled && profileItemsToBeFilled.length > 0) ? profileItemsToBeFilled[promptToShowIndex] : null;

    if (!ComponentToShowType) return null;

    const ComponentToShow = ComponentToShowType ? availablePromptTypes[ComponentToShowType] : null;

    const { image, title, subtitle, actionText, formModalTitle } = promptTexts[ComponentToShowType];


    return (
        <CommonPortal>
            <div className="default-modal profile-prompt-modal">
                <div className="default-modal__backdrop">
                    <div className="default-modal__container">
                        <div className="default-modal__header">
                            <div>{modalTitle}</div>
                            <button onClick={handleClose} className="default-modal__header__close">
                                <img src={CloseIcon} alt="close modal" />
                            </button>
                        </div>
                        <>
                            {!showForm ?
                                <div className="profile-prompt__container">
                                    <img src={image} alt={title} className="profile-prompt__placeholder" />
                                    <h4 className="profile-prompt__title">{title}</h4>
                                    <h5 className="profile-prompt__subtitle">{subtitle}</h5>
                                    {ComponentToShowType === 'image_meta' ? <>
                                        <label htmlFor="profile-prompt-image-input" className="profile-prompt__action">{actionText}</label>
                                        <input
                                            id="profile-prompt-image-input"
                                            type="file"
                                            accept="image/jpg,image/png,image/jpeg"
                                            onInput={handleMediaChange}
                                            hidden
                                            disabled={isUpdating}
                                        />
                                    </> : <button className="button profile-prompt__action" onClick={toggleForm}>{actionText}</button>}
                                    <Link
                                        className={`profile-prompt__link ${isUpdating ? 'disabled' : ''}`}
                                        to={isUpdating ? null : ProfileUrls.userProfileLink(profile.handle, profile.id)}
                                    >
                                        View Profile
                                    </Link>
                                </div> :
                                <>
                                    <ComponentToShow
                                        {...props}
                                        form={form}
                                        setForm={setForm}
                                        handleMediaChange={handleMediaChange}
                                        handleUpdate={handleUpdate}
                                        profile={profile}
                                        handleClose={handleClose}
                                        type={ComponentToShowType}
                                        getOTPWithDispatch={getOTPWithDispatch}
                                    />

                                </>
                            }
                        </>
                    </div>
                </div>
            </div>
            <ToastContainer key="prompt-toast" className="profile-prompt__toast" />
        </CommonPortal>

    );
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user.user,
    };
};

const mapDispatchToProps = {
    updateUser,
    otpVerify,
    getOTP,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePrompt);